<template>
  <div class="index">
    <div class="studentInfo">
      <div class="imgBox">
        <img src="../../assets/img/studyImg.png" alt="">
      </div>
      <div style="margin-top:3px">
        <p @click="startRecord">录音</p>
        <p @click="endRecord">停止录音</p>
      </div>
    </div>
  </div>
</template>

<script>
import Recorder from 'js-audio-recorder'
let recorder = new Recorder();
export default {
  data() {
    return {
      studentName: "",
      duration: "",
    }
  },
  methods: {
    linkUrl(url) {
      this.$router.push({ name: url });
    },
    // 开始录音
    startRecord() {
      recorder.start().then(() => {
        recorder.onprogress = function (params) {
          // this.duration = params.duration.toFixed(2)
          // console.log('dur--', this.duration)
          console.log('录音时长(秒)', params.duration.toFixed(2));
          // let dataArray = recorder.getRecordAnalyseData();
          // console.log('dataArray--', dataArray)
        }

      }, (error) => {
        // 出错了
        console.log(`${error.name} : ${error.message}`);
      })
    },
    // 结束录音
    endRecord() {
      recorder.stop();
      console.log('结束录音')
      this.upload();
    }, // 上传wav格式录音文件
    upload() {

      let blob = recorder.getWAVBlob()
      let formData = new FormData()
      formData.append('file', blob)
      this.$axios.post("/api/JobTask/StudentVideoUpload", formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        var data = JSON.parse(res.data);
        if (data.code == 200) {
          console.log(data);
          console.log('上传录音文件成功！')
        } else {
          console.log('上传录音文件失败！')
        }
      })

    }
  },
  mounted() {

  },
}
</script>

<style>
.index {
  position: relative;
  padding: 20px;
}
.index .studentInfo {
  display: flex;
}
.index .imgBox {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}
.index img {
  width: 100%;
  height: 100%;
}
.index .studyBox {
  width: 100%;
  height: 204px;
  border: 1px solid #bbb;
  margin-top: 12px;
}
.index ul {
  display: flex;
  justify-content: space-between;
}
.index ul li {
  width: 45%;
  height: 180px;
  border: 1px solid #bbb;
  margin: 0 2.5%;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
}
.index ul li div {
  width: 100%;
  height: 100px;
  margin-top: 20px;
}
.index ul li p {
  margin-top: 20px;
  margin-left: 12px;
}
</style>